export const AIRTEAM_EMAIL = 'info@airteam.ai' as const

// external URLs

export const CONTACT_LOCATION_URL =
	'https://goo.gl/maps/jS5VMLoY2kx5rhmr6' as const
export const LOGIN_URL = 'https://airteam.cloud' as const
export const PILOT_SIGNUP_URL =
	'https://airteam.cloud/pilot/registration/' as const
export const ZENDESK_URL = 'https://airteam.zendesk.com' as const
export const JOB_POSTINGS = 'https://airteam.heavenhr.com/jobs' as const
export const INDUSTRY_CARPENTER_URL =
	'https://mafellversum.airteam.ai/' as const

export const PARTNERSHIP_URL = 'https://drone.airteam.ai/partner ' as const

// integrations

/**
 * Changing this variable probably violates the GDPR. Consult a lawyer if necessary.
 */
export const ZENDESKCHAT_NEEDS_COOKIE_PERMISSION = true

/**
 * Recaptcha public key
 */
export const GOOGLE_RECAPTCHA_SITE_KEY =
	'6LeBs0YaAAAAABH8PjUvgXjGvBoFYK-UVozmya7C' as const

/**
 * Changing this variable probably violates the GDPR. Consult a lawyer if necessary.
 */
export const TRUSTPILOT_NEEDS_COOKIE_PERMISSION = true

/**
 * Increment this to invalidate the cache
 * This, for example, re-shows the cookie banner if the legal text has changed
 */
export const CACHE_VERSION = 1
export const COOKIE_BANNER_SHOW_BY_DEFAULT = true

/**
 * If you want your app to work offline and load faster, you can toggle this variable.
 * Note this comes with some pitfalls.
 * @see {@link https://bit.ly/CRA-PWA Learn more about service workers}
 */
export const REGISTER_SERVICE_WORKER = false

// common variables
export const ARROW_HEIGHT_LARGE = 124 as const
export const ARROW_HEIGHT_MEDIUM = 81 as const // FIXME: What’s the actual value?
export const ARROW_HEIGHT_SMALL = 38 as const
