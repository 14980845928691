import React, { useEffect } from 'react'
import ReactPixel from 'react-facebook-pixel'
import { Switch, Route, useLocation } from 'react-router-dom'

// import { CookieState } from './store/modules/cookies'
import { AboutView, ABOUT_PATH } from './views/about/View'
import { BlogDetailView, BLOG_DETAIL_PATH } from './views/blog/DetailView'
import { BlogView, BLOG_PATH } from './views/blog/View'
import { CareerView, CAREER_PATH } from './views/career/View'
import { ContactView, CONTACT_PATH } from './views/contact/View'
import {
	EuropeanUnionView,
	EUROPEAN_UNION_PATH,
} from './views/european-union/View'
import { HomeView, HOME_PATH } from './views/home/View'
import {
	IndustryRoofingView,
	INDUSTRY_ROOFING_PATH,
} from './views/industry/RoofingView'
import {
	IndustrySolarView,
	INDUSTRY_SOLAR_PATH,
} from './views/industry/SolarView'
import {
	IndustrySurveyingView,
	INDUSTRY_SURVEYING_PATH,
} from './views/industry/SurveyingView'
import {
	IndustryRealEstateView,
	INDUSTRY_REAL_ESTATE_PATH,
} from './views/industry/RealEstateView'
import {
	IndustryFacadeView,
	INDUSTRY_FACADE_PATH,
} from './views/industry/FacadeView'
import { AGBView, AGB_PATH } from './views/legal/AGBView'
import { ImprintView, IMPRINT_PATH } from './views/legal/ImprintView'
import {
	PrivacyPolicyView,
	PRIVACY_POLICY_PATH,
} from './views/legal/PrivacyPolicyView'
import {
	NewsletterConfirmationView,
	NEWSLETTER_CONFIRMATION_PATH,
} from './views/newsletter/View'
import { NotFoundView } from './views/not-found/View'
import { PricingView, PRICING_PATH } from './views/pricing/View'
import { ProductView, PRODUCT_PATH } from './views/product/View'
import {
	RegistrationConfirmationView,
	REGISTRATION_CONFIRMATION_PATH,
} from './views/registration-confirmation/View'
import { RegistrationView, REGISTRATION_PATH } from './views/registration/View'
import { PartnerView, PARTNER_PATH } from './views/partner/View'

const Routes = () => {
	const location = useLocation()
	useEffect(() => {
		// if (CookieState.ACCEPTED) {
		ReactPixel.pageView()
		// }
	}, [location.pathname])

	return (
		<Switch>
			<Route component={HomeView} exact path={HOME_PATH} />
			<Route component={AboutView} exact path={ABOUT_PATH} />
			<Route component={BlogView} exact path={BLOG_PATH} />
			<Route component={BlogDetailView} exact path={BLOG_DETAIL_PATH} />
			<Route component={CareerView} exact path={CAREER_PATH} />
			<Route component={ContactView} exact path={CONTACT_PATH} />
			<Route component={PricingView} exact path={PRICING_PATH} />
			<Route component={PrivacyPolicyView} exact path={PRIVACY_POLICY_PATH} />
			<Route component={ImprintView} exact path={IMPRINT_PATH} />
			<Route component={AGBView} exact path={AGB_PATH} />
			<Route component={ProductView} exact path={PRODUCT_PATH} />
			<Route component={PartnerView} exact path={PARTNER_PATH} />
			<Route component={RegistrationView} exact path={REGISTRATION_PATH} />
			<Route
				component={RegistrationConfirmationView}
				exact
				path={REGISTRATION_CONFIRMATION_PATH}
			/>
			<Route component={EuropeanUnionView} exact path={EUROPEAN_UNION_PATH} />
			<Route
				component={IndustryRoofingView}
				exact
				path={INDUSTRY_ROOFING_PATH}
			/>
			<Route
				component={IndustrySurveyingView}
				exact
				path={INDUSTRY_SURVEYING_PATH}
			/>
			<Route component={IndustrySolarView} exact path={INDUSTRY_SOLAR_PATH} />
			<Route
				component={IndustryRealEstateView}
				exact
				path={INDUSTRY_REAL_ESTATE_PATH}
			/>
			<Route component={IndustryFacadeView} exact path={INDUSTRY_FACADE_PATH} />
			<Route
				component={NewsletterConfirmationView}
				exact
				path={NEWSLETTER_CONFIRMATION_PATH}
			/>
			<Route component={NotFoundView} />
		</Switch>
	)
}

export { Routes }
