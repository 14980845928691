import { useEffect } from 'react'

import { ZENDESKCHAT_NEEDS_COOKIE_PERMISSION } from '../data'
import { useStore } from '../store'
// import { CookieState } from '../store/modules/cookies'

const ZenDeskChatIntegration = () => {
	// const { didAcceptCookies } = useStore()

	// ZenDeskChat uses cookies, and thus cannot be enabled until the cookies were accepted
	useEffect(() => {
		if (ZENDESKCHAT_NEEDS_COOKIE_PERMISSION) {
			// @ts-expect-error Zendesk Chat injects itself into window
			if (window.zeESettings) window.zESettings = { cookies: false }
		}
		// if (didAcceptCookies === CookieState.ACCEPTED) {
		/* eslint-disable @typescript-eslint/no-unsafe-call */
		// @ts-expect-error Zendesk Chat injects itself into window
		if (window.zeESettings) window.zESettings = { cookies: true }
		/* eslint-enable @typescript-eslint/no-unsafe-call */
		// }
	}, [])
	return null
}

export { ZenDeskChatIntegration }
